//Importamos la instancia axios del fichero request.js
import http from "@/utils/request";

//Clase para enviar peticiones al servidor relacionadas con los dispositivos
class PlannerService {
    /**
     * Busca todos los parámetros de lso formularios
     * @returns Array de objetos de los formularios
     */
     getFormsOptions(){
        return http.get("tasks/options");
    }

    /**
     * Solicita un nuevo plan de optimizacion
     * @returns Estado de la solicitud
     */
     runNewPlan(data){
        return http.post("tasks/runNewPlan", data, { timeout: 0 });
    }

    /**
     * Pide generar un fichero de exportacion excel
     */
     requestFile(data){
        return http.post(`tasks/generateExcel`,data);
    }    
}

//Exportamos la instancia de la clase
export default new PlannerService();