<template>
  <div class="w-100 maxWrapperWidth m-4">
    <!-- Selector wizard compatible con rutas de URL-->
    <StepSelector ref="stepSelector" class="mt-2 mb-2" :step="1" />

    <!-- Selector de opciones básicas -->
    <div class="card mt-2">
      <div class="card-body">
        <p class="titleWizard text-center mt-4">
          <span class="circled-number">1</span> Seleccione los parámetros
          iniciales para calcular el plan de aducción
        </p>
        <hr class="separador" />
        <Options
          ref="options"
          class="d-flex justify-content-center mt-4"
          :reusePlan="reusePlan"
        />
      </div>
    </div>

    <!-- Configuracion de volumenes, maximos y minimos -->
    <div class="card mt-2">
      <div class="card-body">
        <p class="titleWizard text-center mt-4">
          <span class="circled-number">2</span> Seleccione la disponibilidad de
          los Embalses según la quincena
        </p>
        <hr class="separador" />
        <Availability
          class="p-4 mt-3"
          :reusePlan="reusePlan"
          :altAvaDataset="v_availability[0]"
          :viewMode="false"
        />
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <p class="titleWizard text-center mt-4">
          <span class="circled-number">3</span> Seleccione los volúmenes de
          aducción máximos y mínimos de los Embalses
        </p>
        <hr class="separador" />
        <div class="row col-12 cursor-pointer mb-1 mt-4">
          <div class="col-3 offset-md-1">
            <Select
              v-model="selectedStartDate"
              id="startDateSelector"
              icon="fa-solid fa-calendar-day"
              label="* Seleccione los umbrales de volumenes máximos y mínimos con los que se desea calcular el plan"
              :options="startDateList"
              valKey="key"
              textKey="name"
              style="min-width: 475px"
              @change="updateSelectedStarDate"
              :disabled="false"
            />
          </div>
        </div>
        <Volumes
          id="vol0101"
          ref="vol0101"
          class="mt-2 ps-4 pe-4"
          :reusePlan="reusePlan"
          selectedStartDate="01/01"
          @updateVolumesValues="updateVolumesValues"
        />
        <Volumes
          id="vol0111"
          ref="vol0111"
          v-if="selectedStartDate == '01/11'"
          class="mt-2 ps-4 pe-4"
          :reusePlan="reusePlan"
          selectedStartDate="01/11"
          @updateVolumesValues="updateVolumesValues"
        />
        <span class="notaLegeneda ps-4"
          ><b>Nota:</b> Si selecciona una fecha superior a "<i>Anual</i>"
          todos los valores previos serán considerados en el cálculo</span
        >
      </div>
    </div>

    <!-- Configuracion de trasvases y reajustes -->
    <div class="mt-2">
      <AdvancedOptions :reusePlan="reusePlan" />
    </div>

    <!-- INIT Selector avance -->
    <div class="mt-3">
      <StepActions :step="1" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PlannerService from "@/services/PlannerService";
import PrevisionService from "@/services/PrevisionService";
import StepSelector from "./../../common/Steps-selector.vue";
import StepActions from "./../../common/Steps-actions.vue";
import Availability from "./../../common/step1-preconfigure/Availability";
import Volumes from "./../../common/step1-preconfigure/Volumes";
import AdvancedOptions from "./../../common/step1-preconfigure/AdvancedOptions";
import Options from "./../../common/step1-preconfigure/Options";
import BlockFull from "@/components/commons/BlockFull";
import Select from "@/components/commons/Select";

export default {
  data() {
    return {
      reusePlan: false,
      startDateList: [],
      selectedStartDate: null,
      volumenesRaw: null,
    };
  },
  components: {
    Options,
    Availability,
    Volumes,
    AdvancedOptions,
    BlockFull,
    StepSelector,
    StepActions,
    Select,
  },
  computed: {
    ...mapState("planner", [
      "v_selectedYear",
      "v_selectedCriteria",
      "v_isFinished",
      "v_availability",
      "v_volumes",
      "v_updSelectedStartDate",
      "v_reusePlan",
    ]),
  ...mapGetters('planner', [
    'g_getReusePlan', // -> this.someGetter
  ])    
  },
  watch: {
    //Este watch vigila cambios en la variable reactiva del año
    v_selectedYear: function (newVal, oldVal) {
      //Cuando detecta un cambio ejecutamos el método de recuperar datos
      this.retrieveVolsData();
    },
    selectedStartDate: async function (newVal, oldVal) {
      if (newVal == "01/11") {
        // Improtante esperar el nexttick hasta que se renderice por v-if, sino accede a un elemento inexistente
        await this.$nextTick();
        if (
          this.v_reusePlan &&
          this.g_getReusePlan &&
          this.v_reusePlan.selectedStartDate == "01/11"
        ) {
          this.volumenesRaw = await this.g_getReusePlan;
        } else {
          await this.$nextTick();
          for await (let item of this.volumenesRaw) {
            item["01/11"] = item["01/01"];
          }
        }
        await this.$refs.vol0111.populateFromExt(this.volumenesRaw);
        /* Si se selecciona unicamente el primer item del desplegable 01/01 nos cargamos los elementos 01/11 */
      } else if (newVal == "01/01" && this.volumenesRaw != null) {
        for await (let item of this.volumenesRaw) {
          // Ojo que nos vamos a cargar los elementos de o1 de noviembre para dejar limpio el array
          if (item["01/11"]) {
            delete item["01/11"];
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("planner", ["v_updVolumes", "v_updCapacity"]),
    updateSelectedStarDate() {
      //this.v_updSelectedStartDate(this.selectedStartDate);
    },
    updateVolumesValues(index, selectedDate, data) {
      //console.log(index, selectedDate, data);
      this.volumenesRaw[index][selectedDate] = data;
      this.v_updVolumes(this.volumenesRaw);
    },
    async retrieveVolsData() {
      //Llamada al servicio de obtención de datos de capacidades y volúmenes
      let responseCapacities = await PrevisionService.getCapacities();
      if (responseCapacities) {
        let auxCapacities = {};
        responseCapacities.data.forEach((element) => {
          auxCapacities[element.Nombre] = element.Capacidad;
        });
        await this.v_updCapacity(auxCapacities);
      }
      await PrevisionService.getCapacitiesAndVolumesCurrent(this.v_selectedYear)
        .then((response) => {
          this.volumenesRaw = response.data;
          this.$refs.vol0101.populateFromExt(response.data);
          // Set initial vuex store
          this.v_updVolumes(this.volumenesRaw);
        })
        .catch((err) => {
          console.log(
            "Se ha producido un error al solicitar los volúmenes: " + err
          );
        });
    },
  },
  async mounted() {
    // Comprobar si es un plan reusable
    if (this.$route.query.reuseplan) {
      this.reusePlan = true;
    } else {
      this.reusePlan = false;
    }

    // Obtener listado de fechas para volumenes
    let response_01 = await PlannerService.getFormsOptions();
    if (response_01.data) {
      // Populate selects
      this.startDateList = response_01.data.startDates;
      // Select defaults
      if (
        this.reusePlan == true &&
        this.v_reusePlan &&
        this.v_reusePlan.selectedStartDate
      ) {
        this.selectedStartDate = await this.v_reusePlan.selectedStartDate;
      } else {
        this.selectedStartDate = await this.startDateList[0].key;
      }
    } else {
      console.log(
        "No se pudo obtener datos desde el backend. Contacte con el desarrollador"
      );
    }

    //Obtenemos los volumenes
    if (this.reusePlan == true) {
      //console.log(this.g_getReusePlan)
      if (this.v_reusePlan && this.v_reusePlan.selectedStartDate && this.g_getReusePlan) {
        this.volumenesRaw = this.g_getReusePlan;
        if (this.v_reusePlan.selectedStartDate == "01/01") {
          this.$refs.vol0101.populateFromExt(this.volumenesRaw);
        } else if (this.v_reusePlan.selectedStartDate == "01/11") {
          await this.$refs.vol0101.populateFromExt(this.volumenesRaw);
          await this.$nextTick();
          await this.$refs.vol0111.populateFromExt(this.volumenesRaw);
        }
      }
    } else {
      await this.retrieveVolsData();
    }
  },
};
</script>

<style scoped>
.separador {
  margin: auto;
  max-width: 70%;
  opacity: 0.2;
}

.notaLegeneda {
  font-size: 0.85rem;
}
</style>