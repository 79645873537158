<template>
  <div class="mt-4" :key="componentKey">
    <p class="p-0 m-0 mb-1 ms-1 mensajeEditable" v-if="isEditable">
      <i class="fa-solid fa-circle-exclamation me-1"></i>
      Edite los hm3 de la celda según el criterio deseado ó reinicie los datos al estado
      inicial
      <i
        class="fa-solid fa-rotate iconReset"
        @click="resetValues"
        title="Restaurar valores por defecto"
      ></i>
    </p>
    <!-- Tabla de datos-->
    <div class="table-responsive mt-3" v-if="localDataSet && localDataSet.columnas">
      <table class="table table-hover">
        <thead class="table-light">
          <!-- Primera serie de columnas-->
          <tr class="text-center">
            <th
              v-for="(item, index) in localDataSet.columnas"
              v-bind:key="index"
              :colspan="item.colspan"
            >
              {{ item.label }}
            </th>
          </tr>

          <!-- SubColumnas-->
          <tr class="text-center">
            <template v-for="item in localDataSet.columnas">
              <th v-for="(item2, index2) in item.subLabels" v-bind:key="index2">
                {{ item2 }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody :id="`${this.globalID}`" class="text-center dataTable">
          <tr v-for="(item, index) in localDataSet.filas" v-bind:key="index">
            <td>{{ item.fecha }}</td>
            <td>{{ item.ndias }}</td>
            <template v-for="itemHead in localDataSet.columnas">
              <template v-for="(item2, index2) in item.masasAgua" :key="index2">
                <td
                  :contenteditable="false"
                  editado="false"
                  :id="`${this.globalID}/${index}/${index2}/caudal`"
                  @keydown="isEditable == true ? onKeyDown($event) : ''"
                  @blur="isEditable == true ? onBlur($event) : ''"
                  :style="
                    item2.caudal && item2.caudal.color
                      ? 'background: ' + item2.caudal.color
                      : ''
                  "
                  v-if="
                    item2.caudal && item2.caudal.value && itemHead.label == item2.label
                  "
                  class="unselectable"
                >
                  {{ item2.caudal.value == 0 ? "-" : item2.caudal.value }}
                </td>
                <td
                  :contenteditable="isEditable"
                  :class="item2.label == 'Total Aducido' ? 'unselectable' : ''"
                  editado="false"
                  :id="`${this.globalID}/${index}/${index2}/extraido`"
                  @keydown="isEditable ? onKeyDown($event) : ''"
                  @blur="isEditable ? onBlur($event) : ''"
                  :style="
                    item2.extraido && item2.extraido.color
                      ? 'background: ' + item2.extraido.color
                      : ''
                  "
                  v-if="
                    item2.extraido &&
                    item2.extraido.value &&
                    itemHead.label == item2.label
                  "
                >
                  {{ item2.extraido.value === 0 ? "-" : item2.extraido.value }}
                </td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DataTable",
  props: {
    globalID: {
      type: String,
    },
    dataSet: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    planFromDataBase: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localDataSet: {},
      backupDataSet: {},
      componentKey: 0,
      counter: 0,
    };
  },
  watch: {
    dataSet: function (newVal, oldVal) {
      if (newVal) {
        this.localDataSet = this.copyObject(newVal);
        if (this.counter < 1 && this.planFromDataBase) {
          this.backupDataSet = this.copyObject(newVal);
          this.counter++;
        }
        return newVal;
      }
    },
    v_selectedYear: function (newVal, oldVal) {
      this.counter = 0;
    },
  },
  computed: { ...mapState("planner", ["v_selectedYear"]) },
  components: {},
  async mounted() {
    // Cargamos uan copia para manipular los elementos en caso de que hagan falta
    if (
      this.dataSet &&
      this.dataSet != null &&
      Object.entries(this.dataSet).length !== 0
    ) {
      this.localDataSet = this.copyObject(this.dataSet);
      this.backupDataSet = this.copyObject(this.localDataSet);
    }
  },
  methods: {
    //Cada vez que se pulse una tecla se comprueba primero si es Enter, en cuyo caso impedimos que se ejecute el salto de linea
    //y mandamos el flujo a onBlur, que es donde está la lógica de almacenamiento de datos. Asi emulamos que con Enter el
    //usuario confirma la acción.
    //Después comprobamos con la expresión regular que en el innerHTML de la celda, que será el valor a almacenar, solo pueda
    //haber numeros y un solo punto para mantener la cohesión de datos con el dataprocessing y la base de datos.
    //Permitimos el uso del Backspace para que pueda borrar, las flechas izquierda y derecha.
onKeyDown(e) {
  if (e.key == "Enter") {
    e.preventDefault();
    document.getElementById(e.currentTarget.id).blur();
  } else if (
    e.key != "Backspace" &&
    e.key != "Tab" &&
    e.key != "ArrowLeft" &&
    e.key != "ArrowRight" &&
    e.key != "Delete"
  ) {
    var rgx = /^-?[0-9]*\.?[0-9]*$/;
    if (!rgx.test(e.currentTarget.innerHTML + e.key)) {
      e.preventDefault();
    }
  }
},


    //Almacenamos la celda, consiguiendo su id a través del evento.
    //El id tiene la estructura dada en el v-for correspondiente:
    //<tipoDePlan>/<indice de fila>/<indice de masa de agua>/<tipo de dato> (extraido o caudal)
    //Con el split en "/" obtenemos la posicion de la celda dentro del dataSet del plan. Le pasamos los valores al
    //método que hace comprobaciones y actualizaciones en el dataSet del plan correspondiente.
    onBlur(e) {
      let cell = document.getElementById(e.currentTarget.id);
      let dataCell = cell.id.split("/");
      //dataCell.push(cell.innerHTML);
      this.checkCellAndUpdatePlan(cell, dataCell);
    },
    //Comprobaciones y actualizaciones en base al nombre del plan dentro de un switch:
    // - Usamos el atributo de celda personalizado "editado" para comprobar si es la primera vez que se modifica,
    //   no alterando así el fondo.
    // - Si lo deja vacío lo ponemos en último valor válido guardado para no enviar nulo al dataprocessing.
    // - Finalmente, si es un valor válido diferente al anterior, se hace la llamada al método de vuex para
    //   actualizar el dataSet del Plan, se actualizan la variable editado y se cambia el color de fondo de la celda.
    async checkCellAndUpdatePlan(cell, dataCell) {
      let cellValue = this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]][
        dataCell[3]
      ].value;
      if (cell.innerHTML.length == 0) {
        cell.innerHTML = cellValue;
      } else if (cell.innerHTML != cellValue && cell.innerHTML != "-") {
        // Ojo que se requiere reinsertar como float en vez de texto
        cell.setAttribute("editado", true);
        cell.style.backgroundColor = "lightgreen";
        let fakeDataset;

        if (this.globalID == "planAduccionTable") {
          if ([dataCell[3]] == "extraido") {
            fakeDataset = {
              [dataCell[3]]: {
                value: cell.innerHTML,
                color: "lightgreen",
              },
              caudal: {
                value: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]].caudal
                  .value,
                color: "lightgreen",
              },
              label: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]].label,
              editado: true,
            };
          } else {
            fakeDataset = {
              [dataCell[3]]: {
                value: cell.innerHTML,
                color: "lightgreen",
              },
              extraido: {
                value: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]]
                  .extraido.value,
                color: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]]
                  .extraido.color,
              },
              label: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]].label,
              editado: true,
            };
          }
        } else {
          fakeDataset = {
            [dataCell[3]]: {
              value: cell.innerHTML,
            },
            label: this.localDataSet.filas[dataCell[1]].masasAgua[dataCell[2]].label,
            editado: true,
          };
        }
        this.localDataSet.filas[dataCell[1]].masasAgua.splice(
          [dataCell[2]],
          1,
          fakeDataset
        );
        this.emitValues(this.localDataSet, dataCell);
      }
    },

    resetCellColors() {
      let cells = document.getElementById(this.globalID).getElementsByTagName("td");
      if (cells.length > 0) {
        for (let j = 0; j < cells.length; j++) {
          if (cells[j].getAttribute("editado") === "true") {
            cells[j].style.backgroundColor = "";
          }
        }
      }
    },

    resetValues() {
      this.localDataSet = this.copyObject(this.backupDataSet);
      // Forzar renderizando del encapulsador html
      this.componentKey += 1;
      //Reiniciamos colores en ambos casos
      this.resetCellColors();
    },

    //Funcion para copiar el objeto y que se deslinke la misma referencia en memoria
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    emitValues(newValues, pos) {
      this.$emit("updateValues", newValues, pos);
    },
  },
};
</script>

<style scoped>
/* Estilo de la tabla */
table {
  border: 1px solid #dfe6ec;
}
table th {
  font-size: 0.77rem;
  border-right: 1px solid #dfe6ec;
}

table td {
  font-size: 0.77rem;
  border-right: 1px solid #dfe6ec;
}

.imgEmptyData {
  max-height: 10rem;
}

.noteBg {
  font-size: 0.876rem;
  text-align: justify;
}

.mensajeEditable {
  font-size: 0.8rem;
  font-style: oblique;
  color: #af4444;
}

.iconReset {
  cursor: pointer;
}

.iconReset:hover {
  cursor: pointer;
  transition: 1.5s;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.unselectable {
  background-color: #ddd;
  cursor: not-allowed;
}
</style>
