import http from "@/utils/request";
import store from '@/store'

class MediaService {

    downloadFile(id, endpoint, customfilename) {
        let config = {
            headers: {
                'customfilename': customfilename
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = (progressEvent.loaded / 1024 / 1024);

                store.dispatch("media/addToDownloadQueue", {
                    id: id,
                    downloadProgress: percentCompleted.toFixed(2),
                    downloadLoaded: progressEvent.loaded,
                    downloadTotal: progressEvent.total
                });

                if (progressEvent.loaded == progressEvent.total) {
                    store.dispatch("media/removeFromDownloadQueue", {
                        id: id
                    });
                }
            }
        }
        return http.get(endpoint, config);
    }



    uploadFile(id, formData, endpoint) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'customfilename': store.state.planner.v_selectedCustomFilename,
                'uuid': store.state.planner.v_uuid
            },
            onUploadProgress: (progressEvent) => {
                store.dispatch("media/addToUploadQueue", {
                    id: id,
                    uploadLoaded: progressEvent.loaded,
                    uploadTotal: progressEvent.total
                });
            },
        }

        return http.post(endpoint, formData, config);
    }


}

export default new MediaService();