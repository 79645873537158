<template>
  <div>
    <div class="card accordion accordion-flush mt-3" id="accordionFlushExample">
      <!-- ITEM 1 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            <span class="titleWizard">
              <span class="circled-number me-2" style="zoom: 1">4</span> * Modo
              avanzado: Trasvases / Caudal Ecológico y Aportaciones / Reajustes
            </span>
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
        >
          <div class="accordion-body p-4">
            <!------------- INIT CONTENT DLE ACCORDION ---------------->
            <!-- PESTAÑAS -->
            <div class="card">
              <div class="card-body h-100 p-0">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <!-- INICIO -->
                    <ul class="nav nav-tabs nav-fill w-100">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="volTrasvases"
                          data-bs-toggle="tab"
                          href="'#ssvolTrasvases"
                          role="tab"
                          aria-controls="volTrasvases"
                          aria-selected="true"
                          ><i class="fa-solid fa-bridge-water"></i> Trasvases / Caudal Ecológico
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="volReajustes"
                          data-bs-toggle="tab"
                          href="'#ssvolReajustes"
                          role="tab"
                          aria-controls="volReajustes"
                          aria-selected="true"
                          ><i class="fa-solid fa-cloud-showers-water"></i> Aportaciones / Reajustes
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- PESTAÑAS -->
            <!-- TABS -->
            <div class="row mt-0 ms-1 me-1">
              <div class="tab-content p-0" id="myTabContent">
                <!-- TAB Volumen Reajustes -->
                <div
                  class="tab-pane fade show"
                  id="ssvolReajustes"
                  role="tabpanel"
                  aria-labelledby="ssvolReajustes-tab"
                >
                  <!-- h6 class="text-center mt-2 tableTitle">{{ reajustesTitle }}</h6> -->
                  <DataTable
                    ref="volReajustesTable"
                    globalID="volReajustesTable"
                    :dataSet="localReajustes"
                    :isEditable="true"
                    @updateValues="updateReajustes"
                  />
                </div>

                <!-- TAB Volumen Trasvases -->
                <div
                  class="tab-pane fade show active"
                  id="ssvolTrasvases"
                  role="tabpanel"
                  aria-labelledby="ssvolTrasvases-tab"
                >
                  <!-- <h6 class="text-center mt-2 tableTitle">{{ trasvasesTitle }}</h6> -->
                  <DataTable
                    ref="volTrasvasesTable"
                    globalID="volTrasvasesTable"
                    :dataSet="localTrasvases"
                    :isEditable="true"
                    @updateValues="updateTrasvases"
                  />
                </div>
              </div>
            </div>
            <!-- TABS -->
            <!------------- END CONTENT DLE ACCORDION ---------------->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import DataTable from "./../DataTable";
import Select from "@/components/commons/Select";
import UploadButton from "@/components/commons/UploadButton";
import PrevisionService from "@/services/PrevisionService";

export default {
  data() {
    return {
      localTrasvases: {},
      localReajustes: {},
    };
  },
  props: {
    reusePlan: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Select,
    UploadButton,
    DataTable,
  },
  watch: {
    v_selectedYear: function (newVal, oldVal) {
      this.getTransfersAndReadjustments();
    },

    reusePlan: async function (newVal, oldVal) {
      if (newVal == true) {
        await this.populateData(this.v_reusePlan.availability);
      }
    },
  },
  computed: {
    ...mapState("planner", [
      "v_selectedYear",
      "v_transfers",
      "v_readjustments",
      "v_reusePlan",
    ]),
    reajustesTitle: function (val) {
      let titulo = "REAJUSTES EN " + this.v_selectedYear;
      return titulo;
    },
    trasvasesTitle: function (val) {
      let titulo = "TRASVASES EN " + this.v_selectedYear;
      return titulo;
    },
  },
  methods: {
    ...mapActions("planner", ["v_updTransfers", "v_updReadjustments"]),
    updateTrasvases(newDataSet) {
      this.v_updTransfers(newDataSet);
    },

    updateReajustes(newDataSet) {
      this.v_updReadjustments(newDataSet);
    },

    async getTransfersAndReadjustments() {
      let param = await this.$route.query.reuseplan;
      if (param == "true") {
        this.populateData(await this.v_reusePlan.availability);
      }else if(this.reusePlan == false && param != true){
        let response =
          await PrevisionService.getTranfersAndReadjustmentsInitial(
            this.v_selectedYear
          );
        if (response.data) {
          this.populateData(await response.data);
        } else {
          console.log("Error al recuperar los datos del plan inicial");
        }
}
    },

    populateData(dataRaw) {
      //Reconvertimos a los arrays necesarios
      let dataSet = {
        planReajustes: {
          columnas: [{ colspan: "2", label: "", subLabels: ["Fecha", "Dias"] }],
          filas: [],
        },
        planTrasvases: {
          columnas: [{ colspan: "2", label: "", subLabels: ["Fecha", "Dias"] }],
          filas: [],
        },
      };

      /** Recorremos el array y metemos las columnas evitadno qeu se dupliquen **/
      /************************************************************************ */
      for (let item of dataRaw) {
        // Meter cabeceras en planReajustes y planTrasvases
        if (item) {
          const itemExists = dataSet.planTrasvases.columnas.some(
            (itemloc) => itemloc.label === item.Nombre_Embalse
          );
          if (!itemExists) {
            dataSet.planTrasvases.columnas.push({
              colspan: "1",
              label: item.Nombre_Embalse,
              subLabels: ["hm3"],
            });
            dataSet.planReajustes.columnas.push({
              colspan: "1",
              label: item.Nombre_Embalse,
              subLabels: ["hm3"],
            });
          }
        }

        //Meter fechas en todos los casos
        const itemExists = dataSet.planTrasvases.filas.some(
          (itemloc) => itemloc.fecha === item.Fecha
        );
        if (!itemExists) {
          dataSet.planReajustes.filas.push({
            fecha: item.Fecha,
            masasAgua: [],
            ndias: item.Dias,
          });
          dataSet.planTrasvases.filas.push({
            fecha: item.Fecha,
            masasAgua: [],
            ndias: item.Dias,
          });
        }
      }

      /** Recorremos el array extrayendo los datos y rellenando los items inmediatos **/
      /****************************************************************************** */
      for (let item of dataRaw) {
        // Para rellenar el plan de Trasvases y reajustes
        let index = dataSet.planTrasvases.filas.findIndex(
          (item2) => item2.fecha === item.Fecha
        );
        if (index != -1) {
          const itemExists = dataSet.planTrasvases.columnas.some(
            (itemloc) => itemloc.label === item.Nombre_Embalse
          );
          if (itemExists) {
            dataSet.planReajustes.filas[index].masasAgua.push({
              extraido: { value: item.Reajustes ? item.Reajustes : "0" },
              label: item.Nombre_Embalse,
            });
            dataSet.planTrasvases.filas[index].masasAgua.push({
              extraido: { value: item.Trasvases ? item.Trasvases : "0" },
              label: item.Nombre_Embalse,
            });
          }
        }
      }

      let list2 = [
        "",
        "Aracena",
        "Zufre",
        "La Minilla",
        "El Gergal",
        "Cala",
        "Los Melonares",
      ];

      // Ordenar Columnas PlanReajustes y PlanTrasvases
      let auxArray = new Array(dataSet.planReajustes.columnas);
      let index = null;
      for (let i = 0; i < list2.length; i++) {
        index = dataSet.planReajustes.columnas.findIndex(
          (item) => item.label == list2[i]
        );
        auxArray[i] = dataSet.planReajustes.columnas[index];
      }
      dataSet.planReajustes.columnas = auxArray;
      dataSet.planTrasvases.columnas = auxArray;

      // Para uso local
      this.localTrasvases = dataSet.planTrasvases;
      this.localReajustes = dataSet.planReajustes;
      
      if(this.localTrasvases.filas.length > 24){
        this.localTrasvases.filas.splice(-1,1)
      }

      if(this.localReajustes.filas.length > 24){
        this.localReajustes.filas.splice(-1,1)
      }

      // Per defecto rellenamos el almacen de vuex
      this.v_updTransfers(dataSet.planTrasvases);
      this.v_updReadjustments(dataSet.planReajustes);
    },
  },
  async mounted() {
    if (
      Object.entries(this.localTrasvases).length === 0 ||
      Object.entries(this.localReajustes).length === 0
    ) {
      if (this.v_selectedYear != null) {
        await this.getTransfersAndReadjustments();
      }
    }
  },
};
</script>

<style scoped>
/* Sobreescribir configuraciones por defecto */
.card {
  max-width: 100%;
}

.nav-tabs .nav-link {
  color: gray;
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link:hover {
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link.active {
  color: #000000;
  border: 0;
  border-radius: 1;
  border-bottom: 2px solid #4ca1ef;
  background-color: #cde9ff;
}

.nav-item:hover {
  background-color: #f0f7fc;
  transition: background-color 0.5s ease;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}
</style>