<template>
  <div>
    <input
      class="form-control"
      type="file"
      :class="compClass"
      :disabled="disabled"
      :required="required"
      :id="id"
      :accept="accept"
      @change="onFileChange"
      v-if="!isUploading"
    />
    <!-- Boton importar estandar -->
    <button
      class="w-100 btn btn-success btnSubmit"
      type="submit"
      @click="performUpload"
      v-if="selectedFile != null && mode == 'basic'"
    >
      <i class="fas fa-arrow-alt-circle-up"></i>
      <span v-if="isUploading" class="text-uppercase span-advanced-settings"
        >Subiendo... {{ totalPercentLabel }} Mb
      </span>
      <span v-else class="text-uppercase span-advanced-settings">
        {{ localLabel }}
      </span>
    </button>
    <!-- o barra de progreso para eventos forzados -->
    <div
      class="progress"
      style="height: 38px"
      v-if="isUploading && mode == 'inline'"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuemin="0"
        aria-valuenow="0"
        :style="progressBarStyle"
        :aria-valuemax="totalSize"
      >
        {{ totalPercentLabel }}
      </div>
    </div>
  </div>
</template>



<script>
import { mapState } from "vuex";
import store from "@/store";
import MediaService from "@/services/MediaService";

export default {
  name: "UploadButton",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "...",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compClass: {
      type: String,
    },
    endpoint: {
      type: String,
      required: true,
      default: null,
    },
    mode: {
      type: String,
      default: "basic",
    },
    required: {
      type: Boolean,
      default: "false",
    },    
    accept: {
      type: String,
      default: "*.*"
    }
  },
  computed: {
    ...mapState("media", ["uploadQueue"]),
    selIndex: function () {
      if (this.uploadQueue) {
        let index = this.uploadQueue.findIndex((x) => x.id == this.id);
        if (index === -1) {
          // Devolvemos 0 en caso no posible
          return -1;
        } else {
          return index;
        }
      }
    },
    totalLoaded: function () {
      if (this.uploadQueue && this.selIndex != -1) {
        return this.uploadQueue[this.selIndex].uploadLoaded;
      }
    },
    totalSize: function () {
      if (this.uploadQueue && this.selIndex != -1) {
        return this.uploadQueue[this.selIndex].uploadTotal;
      }
    },
    localLabel: function () {
      if (!this.isUploading && this.endpoint) {
        return this.label;
      } else {
        return "Endpoint incorrecto";
      }
    },
    totalPercent: function () {
      if (this.uploadQueue && this.selIndex != -1) {
        return Math.round((this.totalLoaded * 100) / this.totalSize);
      }
    },
    totalPercentLabel: function () {
      if (this.uploadQueue && this.selIndex != -1) {
        return this.totalPercent + " %";
      }
    },
    progressBarStyle: function () {
      if (this.uploadQueue && this.selIndex != -1) {
        let estilo = "width: " + this.totalPercent + "% ;";
        return estilo;
      }
    },
  },
  watch: {
    totalPercent: function (newVal, oldVal) {
      if (newVal == 100) {
        store.dispatch("media/removeFromUploadQueue", {
          id: this.id,
        });
        this.isUploading = false;
      }
    },
  },
  data() {
    return {
      selectedFile: null,
      isUploading: false,
    };
  },
  mounted() {},
  methods: {
    /* Funcion para detectar el cambio de ficheros */
    async onFileChange(e) {
      console.log(e)
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedFile = files[0];
      this.$emit("filename", this.selectedFile.name);
    },

    /* Boton para hacer la subida manual del fichero */
    async performUpload(e) {
      e.preventDefault();
      await this.uploadFile();
    },

    /* Funcion para subir el fichero */
    async uploadFile() {
      // Crear el formulario con el adjunto
      const formData = new FormData();
      formData.append("attachment", this.selectedFile);
      this.isUploading = true;

      // Subir el fichero
      await MediaService.uploadFile(this.id, formData, this.endpoint);
    },

  },
};
</script>