<template>
  <div class="card">
    <div class="row card-header" v-if="titulo">
      <div :class="'d-flex align-items-center col col-sm-8 col-md-'+titleCol">
        <h5 class=" card-title mb-0 ms-2 mainTitle">{{ titulo }}</h5>
        <div class="subTitle">{{ subTitle }}</div>
      </div>
      <div class="col" v-if="enabledOptions">
        <slot name="options"></slot>
      </div>
    </div>
    <div class="card-body" :class="claseBody">
      <div class="row" style="padding:0 10px 0 10px;">
        <!-- SLOT -->
        <slot name="content"></slot>
        <!-- SLOT -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockFull",
  props: {
    titulo: {
      type: String,
      default: null,
    },
    claseBody: {
      type: String
    },
    titleCol: {
      type: Number,
      default: 8
    },
    subTitle: {
      type: String,
      default: null
    },
    enabledOptions: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style scoped>
.card {
  max-width: initial !important;
}

.subTitle{
  opacity: 0.7;
  font-size: 0.75rem;
}

.mainTitle{
  zoom:1
}

@media (max-width: 476px) {
.mainTitle{
  font-size: 1rem
}
}

</style>