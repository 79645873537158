//Importamos la instancia axios del fichero request.js
import http from "@/utils/request";

//Clase para enviar peticiones al servidor relacionadas con los dispositivos
class PrevisionService {
    /**
     * Busca todos los años distintos almacenados en la base de datos en las Previsiones Pre-Cargadas desde los excel:
     * @returns Array de años
     */
    getYearsPrevActual(){
        return http.get("forecast/years");
    }

    /**
     * Busca todos los años distintos almacenados en la base de datos en las Previsiones Favoritas:
     * @returns Array de años
     */
     getYearsPrevFavorita(){
        return http.get("forecast/years/favorite");
    }

    /**
     * Comprueba en la base de datos si hay algún plan favorito guardado:
     * @returns True o False
     */
    getFavoritePlanExists(){
        return http.get("forecast/favoritePlanExists");
    }
    /**
     * Busca las disponibilidades actuales
     * @returns Array de disponibilidades con nombres de embalse
     */
     getAvailabilityCurrent(year){
        return http.get(`forecast/availability/current?year=${year}`);
    }
    /**
     * Busca las disponibilidades iniciales
     * @returns Array de disponibilidades con nombres de embalse
     */
     getAvailabilityInitial(year){
        return http.get(`forecast/availability/initial?year=${year}`);
    }
    /**
     * Busca las capacidades de todos los embalses
     * @returns Array de capacidades con nombres de embalse
     */
     getCapacities(){
        return http.get(`forecast/capacities`);
    }
    /**
     * Busca las capacidades y volúmenes máximos y mínimos de los embalses actuales en un año dado
     * @returns Array de capacidades y volúmenes con nombres de embalse
     */
    getCapacitiesAndVolumesCurrent(year){
        return http.get(`forecast/capacities/volumes/current?year=${year}`);
    }
    /**
     * Busca las capacidades y volúmenes máximos y mínimos de los embalses iniciales en un año dado
     * @returns Array de capacidades y volúmenes con nombres de embalse
     */
     getCapacitiesAndVolumesInitial(year){
        return http.get(`forecast/capacities/volumes/initial?year=${year}`);
    }
    /**
     * Busca las capacidades y volúmenes máximos y mínimos de los embalses favoritos para el 01/01 y 01/11 en un año dado
     * @returns Array de capacidades y volúmenes con nombres de embalse y fecha
     */
         getCapacitiesAndVolumesFavorite(year){
            return http.get(`forecast/capacities/volumes/favorite?year=${year}`);
        }
        /**
     * Busca los planes de trasvases y reajustes iniciales de todos los embalses de un cierto año
     * @returns Array de trasvases y reajustes con nombres de embalse, fecha y numero de dias
     */
        getTranfersAndReadjustmentsInitial(year){
            return http.get(`forecast/transfers/readjustments/initial?year=${year}`);
        }
    /**
     * Busca las capacidades y volúmenes máximos y mínimos de los embalses iniciales en un año dado
     * @returns Array de capacidades y volúmenes con nombres de embalse
     */
     getIdPlan(){
        return http.get(`forecast/idPlan`);
    }

    getNewestFavoritePlanByYear(year){
        return http.get(`forecast/favoritePlan?year=${year}`);
    }

    /**
     * Inserta en la base de datos un registro de plan favorito
     */
    postFavoritePlan(data){
        return http.post(`forecast/favoritePlan`,data);
    }
    /**
     * Actualiza en la base de datos un registro de plan favorito
     */
     putFavoritePlan(data){
        return http.put(`forecast/favoritePlan`,data);
    }

}

//Exportamos la instancia de la clase
export default new PrevisionService();